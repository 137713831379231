import './Anchor.scss';

import { ComponentPropsWithoutRef } from 'react';

const className = 'c-Anchor';

export default function Anchor(
  props: Omit<ComponentPropsWithoutRef<'a'>, 'className'>,
) {
  return <a className={className} {...props} />;
}
